'use client'

import React from 'react'
import {css, useTheme} from '@emotion/react'

import Tool from './Tool'
import Paper from '../../../../../components/elements/Paper'

export default function ToolPanel({title, subTitle, control, toolInfos, setGroupFilter, ...props}) {
  const theme = useTheme()
  const styles = {
    root: css`
      padding: 24px;
      background: ${theme.palette.surface.main};
    `,

    header: css`
      margin-bottom: 16px;

      display: flex;
      gap: 16px;
      justify-content: space-between;
    `,

    title: css`
      font-size: 1.4rem;
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `,

    content: css`
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 900px) {
        grid-template-columns: repeat(1, 1fr);
      }
    `,
  }

  return (
    <Paper css={styles.root} {...props}>
      <div css={styles.header}>
        <div css={css`
          display: flex;
          gap: 16px;
        `}>
          <div css={styles.title}>{title}</div>
          {subTitle}
        </div>
        {control}
      </div>
      <div css={styles.content}>
        {toolInfos.map(toolInfo => (
          <Tool
            key={toolInfo.key}
            info={toolInfo}
            setGroupFilter={setGroupFilter}
          />
        ))}
      </div>
    </Paper>
  )
}
