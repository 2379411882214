'use client'

import React from 'react'
import {css, useTheme,} from '@emotion/react'
import {useDebounce} from 'use-debounce'
import Fuse from 'fuse.js'
import fp from 'lodash/fp'
import {useTranslations} from 'next-intl'
import {Chip} from '@mui/material'
import {pink} from '@mui/material/colors'
import {FaFireAlt as FaFireAltIcon} from 'react-icons/fa'
import HistoryIcon from '@mui/icons-material/History'

import {ToolCategories} from '../../../../../config/categories.config'
import SearchField from '../../../../../components/elements/SearchField'
import useAllToolInfos from '../../../../../hooks/useAllToolInfos'
import Button from '../../../../../components/elements/Button'
import clearRecentUsedToolKeys from '../../../../../app/utils/clearRecentUsedToolKeys'
import ToolPanel from './ToolPanel'

export default function HomePage({recentUsedToolKeys, mostVisitedToolKeys, query}) {
  const theme = useTheme()
  const styles = {
    root: css`
    `,

    control: css`
      margin-top: 8px;
    `,

    searchField: css`
      flex: 1;
      max-width: 400px;

      & > div {
        height: 48px;
        padding: 8px 16px;
      }
    `,

    content: css`
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    `,

    adFooter: css`
      margin: 0 auto;
      max-width: 728px;
    `,
  }

  const t = useTranslations()

  const allToolInfos = useAllToolInfos()

  const [queryText, setQueryText] = React.useState(query)
  const onSearchTextChange = React.useCallback((queryText) => {
    setQueryText(queryText)
  }, [setQueryText])

  const [debouncedSearchText] = useDebounce(queryText, 20,)

  const [groupFilter, setGroupFilter] = React.useState('')

  const filteredToolInfos = React.useMemo(() => {
    if (debouncedSearchText) {
      const fuse = new Fuse(allToolInfos, {
        keys: [
          {name: 'label', weight: 1},
        ],
        includeMatches: true,
        threshold: 0.1,
      })

      return highlight(fuse.search(debouncedSearchText))
    } else {
      return allToolInfos
    }
  }, [allToolInfos, debouncedSearchText])

  const onRecentUsedToolClearButtonClick = () => {
    clearRecentUsedToolKeys()
  }

  const filterTools = React.useCallback((category) => {
    const filters = [
      fp.filter(toolInfo => !groupFilter || toolInfo.group === groupFilter),
    ]

    switch (category) {
      case 'recent-used':
        return fp.flow(
          ...filters,
          fp.filter(toolInfo => recentUsedToolKeys.includes(toolInfo.key)),
          fp.sortBy(toolInfo => recentUsedToolKeys.indexOf(toolInfo.key)),
        )
      case 'hot':
        return fp.flow(
          ...filters,
          fp.filter(toolInfo => mostVisitedToolKeys.includes(toolInfo.key)),
          fp.sortBy(toolInfo => mostVisitedToolKeys.indexOf(toolInfo.key)),
        )
      default:
        return fp.flow(
          ...filters,
          fp.filter(onlineTool => onlineTool.category === category),
        )
    }
  }, [recentUsedToolKeys, mostVisitedToolKeys, groupFilter])

  const categoryToolInfos = React.useMemo(() => (
    ToolCategories
      .map(toolCategory => ({
        key: toolCategory,
        value: toolCategory,
        label: t(`tool-category.${toolCategory}`),
        tools: filterTools(toolCategory)(filteredToolInfos),
      }))
      .filter(toolCategory => toolCategory.tools.length > 0)
  ), [t, filterTools, filteredToolInfos])


  const PanelTitle = (categoryToolInfo) => {
    switch (categoryToolInfo.key) {
      case 'recent-used':
        return (
          <span
            css={css`
              color: ${theme.palette.primary.dark};
            `}
          >
            <HistoryIcon css={css`
              margin-right: 4px;
            `}/>
            {categoryToolInfo.label}
          </span>
        )
      case 'hot':
        return (
          <span
            css={css`
              color: ${pink[900]};
            `}
          >
            <FaFireAltIcon css={css`
              color: ${pink[700]};
              margin-right: 4px;
            `}/>
            {categoryToolInfo.label}
          </span>
        )
      default:
        return categoryToolInfo.label
    }
  }

  return (
    <div css={styles.root}>
      <div css={styles.control}>
        <div id="home-search" css={styles.searchField}>
          <SearchField
            placeholder={t('home.search-placeholder')}
            query={queryText}
            onQueryChange={onSearchTextChange}
          />
        </div>
      </div>
      <div css={styles.content}>
        {categoryToolInfos.map(categoryToolInfo => (
          <ToolPanel
            variant={['hot', 'recent-used'].includes(categoryToolInfo.key) ? categoryToolInfo.key : undefined}
            key={categoryToolInfo.key}
            title={PanelTitle(categoryToolInfo)}
            subTitle={
              groupFilter && <Chip label={t(`tool-group.${groupFilter}`)} onDelete={() => setGroupFilter('')}/>
            }
            control={
              categoryToolInfo.key === 'recent-used' &&
              <Button secondary onClick={onRecentUsedToolClearButtonClick}>{t('home.clear')}</Button>
            }

            toolInfos={categoryToolInfo.tools}
            setGroupFilter={setGroupFilter}
          />
        ))}
      </div>

      {/*<div css={styles.adFooter}>*/}
      {/*  <Adsense*/}
      {/*    client="ca-pub-9395644566418596"*/}
      {/*    slot="1014541144"*/}
      {/*    format="auto"*/}
      {/*    style={{*/}
      {/*      display: 'block',*/}

      {/*      width: '100vw',*/}
      {/*      maxWidth: 728,*/}
      {/*      height: 90,*/}
      {/*    }}*/}
      {/*    responsive="true"*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  )
}


const highlight = (fuseSearchResult) => {
  const generateHighlightedText = (inputText, regions = []) => {
    let content = ''
    let nextUnhighlightedRegionStartingIndex = 0

    regions.forEach(region => {
      const lastRegionNextIndex = region[1] + 1

      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        `<b>`,
        inputText.substring(region[0], lastRegionNextIndex),
        '</b>',
      ].join('')

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex
    })

    content += inputText.substring(nextUnhighlightedRegionStartingIndex)

    return content
  }

  return fuseSearchResult
    .filter(({matches}) => matches && matches.length)
    .map(({item, matches}) => {
      return fp.flow(
        ...matches.map(match => fp.set(match.key, generateHighlightedText(match.value, match.indices)))
      )(item)
    })
}
